<template>
  <div>
    <div class="navWrap">
      <div class="navWrap_center">
        <div class="navWrap_center_img">
          <img src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/logo.png" alt="" />
        </div>

        <ul class="navWrap_center_ul">
          <li v-for="(item, index) in navList" :key="index" class="dropbtn">
            <div @click="funyuming(item.url)">
              {{ item.title }}
            </div>

            <div class="dropdown-content" v-if="item.child">
              <router-link
                v-for="(ite, index) in item.child"
                :key="index"
                :to="ite.url"
                >{{ ite.title }}</router-link
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        { title: "首页", url: "/" },
        {
          title: "走进九宫艾",
          url: "/zj/0",
          child: [
            { title: "品牌简介", url: "/zj/0" },
            { title: "品牌文化", url: "/zj/1" },
            { title: "品牌实力", url: "/zj/2" },
          ],
        },
        { title: "制艾工艺", url: "/zhizuo" },
        { title: "产品中心", url: "/product" },
        {
          title: "品牌体系",
          url: "/pptx/0",
          child: [
            { title: "线下门店", url: "/pptx/0" },
            { title: "线上商城", url: "/pptx/1" },
            { title: "健康学院", url: "/pptx/2" },
          ],
        },
        {
          title: "品牌动态",
          url: "/ppdt/0",
          child: [
            { title: "企业动态", url: "/ppdt/0" },
            { title: "行业动态", url: "/ppdt/1" },
          ],
        },
        { title: "联系我们", url: "/contact" },
      ],
    };
  },
  methods: {
    funyuming(msg) {
      this.$router.push(msg);
    },
  },
};
</script>

<style>
.navWrap {
  width: 100%;
  position: relative;
  z-index: 1;
}

.navWrap_center {
  width: 70%;
  height: 101px;
  margin: 0 auto;
  background: #fff;
  border-radius: 51px;
  /*  */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navWrap_center_img {
  width: 181px;
  height: 54px;
}

.navWrap_center_img img {
  width: 181px;
}
.navWrap_center_ul{
  /* display: flex;
  justify-content: space-around; */
  /* flex: 1; */
}
.navWrap_center_ul li {
  display: inline-block;
  height: 78px;
  line-height: 78px;
  margin-left: 30px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  position: relative;
}
.navWrap_center_ul li:hover {
  cursor: pointer;
  color: rgba(35, 51, 127, 1);
  border-bottom: 2px solid rgba(35, 51, 127, 1);
}

.dropdown {
  top: 11px;
  left: 394px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  text-align: center;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 95px;
  min-height: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-content a {
  color: rgb(6, 6, 6);
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropbtn:hover .dropdown-content {
  display: block;
}
</style>
<template>
  <div>
    <dheader></dheader>
    <div class="product">
      <div class="productDiv">
        <h1>产品中心</h1>
      </div>
    </div>
    <div class="subMain">
      <img
        class="main-img"
        v-for="(i, idx) in list"
        :key="idx"
        :src="i"
        alt=""
      />
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import dheader from "../../components/dheader.vue";
export default {
  components: {
    footer2,
    dheader,
  },
  data() {
    return {
      list1: [
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product1_1.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product1_2.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product1_3.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product1_4.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product1_5.png"
      ],
      list2: [
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product2_1.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product2_2.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product2_3.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product2_4.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product2_5.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product2_6.png",
      ],
      list3: [
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product3_1.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product3_2.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product3_3.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product3_4.png",
      ],
      list: [],
    };
  },
  mounted() {
    const id = this.$route.query.id;
    if (id == 1) {
      this.list = this.list1;
    }
    if (id == 2) {
      this.list = this.list2;
    }
    if (id == 3) {
      this.list = this.list3;
    }
  },
};
</script>

<style scoped>
.product {
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/lvdi.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 297px;
}
.product .productDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main-img {
  width: 100%;
}
.xueyue {
  margin: 60px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.xueyue-item {
  padding-bottom: 20px;
  text-align: center;
  width: 360px;
  margin-bottom: 30px;
}
.xueyue-item img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  background: #f1f1f1;
}
</style>
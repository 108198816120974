<template>
  <div>
    <dheader></dheader>
    <div class="product">
      <div class="productDiv">
        <h1>8大制作工艺</h1>
      </div>
    </div>
    <div class="subMain">
      <div class="gongyi">
        <div v-for="(item, index) in list" :key="index" style="display: flex">
          <div style="align-items: center">
            <img class="gongyi-icon" :src="item.img" alt="" />
            <div style="margin-top: 10px">{{ item.text }}</div>
          </div>
          <img
            v-if="index != list.length - 1"
            class="gongyi-next"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youy.png"
            alt=""
          />
        </div>
      </div>

      <div class="tianran">
        <div style="text-align: center; font-size: 32px; margin-bottom: 30px">
          甄选天然原料
        </div>
        <div style="font-size: 18px; line-height: 30px">
          根植医圣故里，崛起艾草之乡，九宫艾产品原料均来自自家在南阳的3000亩艾草基地，精选陈年宛艾。南阳自古襟三江而带群湖，枕伏牛而蹬江汉，境内伏牛苍苍，丹水泱泱。典型的季风性大陆半湿润气候，四季分明，阳光充足，雨量充沛。优越的地理位置、气候条件，优质的土地资源，孕育出每一株新鲜肥厚、品质上乘的艾草。
        </div>
      </div>

      <div class="liangshai">
        <div class="liangshai-lf">
          <div class="liangshai-img">
            <img
              src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-1.png"
              alt=""
            />
            <div class="liangshai-imgbut">
              <span class="liangshai-imgbut-s">收割</span>
              <span>五月端午之际采摘</span>
            </div>
          </div>
          <div class="liangshang-text">
            <span>2</span>
            、分离：将艾杆和艾叶分离，仅收艾叶，艾杆还田，或作造纸材料应用。
          </div>
          <div class="liangshai-img">
            <img
              src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-3.png"
              alt=""
            />
            <div class="liangshai-imgbut">
              <span class="liangshai-imgbut-s">晾晒</span>
            </div>
          </div>
          <div class="liangshang-text">
            <span>4</span>
            、陈化：艾叶的陈化近似于茶叶的自然氧化过程，打捆或装袋的干燥艾叶，自然放置于阴凉库房，定期翻晒，存满三年较佳。
          </div>
          <div class="liangshai-img">
            <img
              src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-5.png"
              alt=""
            />
            <div class="liangshai-imgbut">
              <span class="liangshai-imgbut-s">粉碎</span>
              <span>陈化到期的艾叶连续粉碎。</span>
            </div>
          </div>
          <div class="liangshang-text">
            <span>6</span>
            筛分：粉碎后的艾叶经过的铁筛筛分，分离艾绒和艾粉，艾绒进一步精细粉碎或者收集待用。
          </div>
          <div class="liangshai-img">
            <img
              src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-7.png"
              alt=""
            />
            <div class="liangshai-imgbut">
              <span class="liangshai-imgbut-s">制艾</span>
              <span>加工制成各种艾制品</span>
            </div>
          </div>
          <div class="liangshang-text">
            <span>8</span>
            、检验：按照企业质量标准，进行质量检测，包括规格、重量、陈化时间、卫生标准等，合格产品方可包装出厂。
          </div>
        </div>
        <div class="liangshai-rt">
          <div class="liangshang-text">
            <span>1</span>
            、收割：五月端午之际采摘，十二地支中五月称“午月“，阳光充足，阳气最旺，艾为纯阳之草，端午采艾，药用价值最高。
          </div>
          <div class="liangshai-img">
            <img
              src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-2.png"
              alt=""
            />
            <div class="liangshai-imgbut">
              <span class="liangshai-imgbut-s">制艾</span>
              <span>加工制成各种艾制品</span>
            </div>
          </div>
          <div class="liangshang-text">
            <span>3</span>
            、晾晒：艾叶自然晾干，至含水量大约8-10%后，打捆或装袋，做好陈化储存准备。
          </div>
          <div class="liangshai-img">
            <img
              src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-4.png"
              alt=""
            />
            <div class="liangshai-imgbut">
              <span class="liangshai-imgbut-s">陈化</span>
              <span>自然氧化过程，存满三年较佳。</span>
            </div>
          </div>
          <div class="liangshang-text">
            <span>5</span>
            、粉碎：将陈化到期的艾叶连续粉碎备用。
          </div>
          <div class="liangshai-img">
            <img
              src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-6.png"
              alt=""
            />
            <div class="liangshai-imgbut">
              <span class="liangshai-imgbut-s">筛分</span>
              <span>分离艾绒和艾粉</span>
            </div>
          </div>
          <div class="liangshang-text">
            <span>7</span>
            、制艾：用卷烟纸、绵纸或者桑皮纸等作为外裹物，将艾绒填充其中，卷制成各种规格的艾条艾柱，或用专业设备压制成艾饼，以及艾贴艾贴、脐贴等其他艾制品。
          </div>
          <div class="liangshai-img">
            <img
              src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-8.png"
              alt=""
            />
            <div class="liangshai-imgbut">
              <span class="liangshai-imgbut-s">检验</span>
              <span>合格产品方可包装出厂</span>
            </div>
          </div>
        </div>
        <div class="shu">
          <img
            style="left: -6px; top: 5.5%"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/quan.png"
            alt=""
          />
          <img
            style="left: 24px; top: 5.4%; width: 17px; height: 23px"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youyy.png"
            alt=""
          />
          <img
            style="left: -6px; top: 19%"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/quan.png"
            alt=""
          />
          <img
            style="
              left: -40px;
              top: 18.8%;
              width: 17px;
              height: 23px;
              transform: rotate(180deg);
            "
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youyy.png"
            alt=""
          />
          <img
            style="left: -6px; top: 30.9%"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/quan.png"
            alt=""
          />
          <img
            style="left: 24px; top: 30.8%; width: 17px; height: 23px"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youyy.png"
            alt=""
          />
          <img
            style="left: -6px; top: 43.9%"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/quan.png"
            alt=""
          />
          <img
            style="
              left: -40px;
              top: 43.8%;
              width: 17px;
              height: 23px;
              transform: rotate(180deg);
            "
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youyy.png"
            alt=""
          />
          <img
            style="left: -6px; top: 55.3%"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/quan.png"
            alt=""
          />
          <img
            style="left: 24px; top: 55.1%; width: 17px; height: 23px"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youyy.png"
            alt=""
          />
          <img
            style="left: -6px; top: 69%"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/quan.png"
            alt=""
          />
          <img
            style="
              left: -40px;
              top: 68.9%;
              width: 17px;
              height: 23px;
              transform: rotate(180deg);
            "
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youyy.png"
            alt=""
          />
          <img
            style="left: -6px; top: 80.3%"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/quan.png"
            alt=""
          />
          <img
            style="left: 24px; top: 80.1%; width: 17px; height: 23px"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youyy.png"
            alt=""
          />
          <img
            style="left: -6px; top: 94.5%"
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/quan.png"
            alt=""
          />
          <img
            style="
              left: -40px;
              top: 94.4%;
              width: 17px;
              height: 23px;
              transform: rotate(180deg);
            "
            class="shu-item"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/youyy.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import dheader from "../../components/dheader.vue";
export default {
  components: {
    footer2,
    dheader,
  },
  data() {
    return {
      list: [
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-9.png",
          text: "收割",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-10.png",
          text: "分离",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-11.png",
          text: "晾晒",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-12.png",
          text: "陈化",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-13.png",
          text: "粉碎",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-14.png",
          text: "筛分",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-15.png",
          text: "制艾",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/zhiai-16.png",
          text: "检验",
        },
      ],
    };
  },
};
</script>

<style scoped>
.product {
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/lvdi.png")
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 297px;
}
.product .productDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main {
  width: 64%;
  margin: 50px auto;
}
/*  */
.gongyi {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 31px;
  color: #727171;
  justify-content: center;
}
.gongyi-icon {
  width: 98px;
  height: 98px;
}
.gongyi-next {
  width: 13px;
  height: 18px;
  margin: 43px 25px;
}
/*  */
.tianran {
  color: #727171;
  margin: 100px 0;
}
.shu-item {
  width: 14px;
  height: 14px;
  position: absolute;
}
.shu {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 1px;
  background: #12a480;
}
.liangshai {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.liangshai-lf {
  width: 40%;
  margin: 0 auto;
}
.liangshai-rt {
  width: 40%;
  margin: 0 auto;
}
.liangshai-img {
  width: 100%;
  margin: 0 auto;
}
.liangshai-img img {
  width: 100%;
  height: 343px;
  object-fit: fill;
}
.liangshai-imgbut {
  background: #12a480;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  margin-top: -7px;
}
.liangshai-imgbut-s {
  font-size: 24px;
  margin-right: 10px;
}

.liangshang-text {
  color: #12a480;
  margin: 130px auto;
  width: 80%;
}
.liangshang-text span {
  font-size: 40px;
  font-weight: bold;
}
</style>

<template>
  <div>
    <div class="big-box" :style="{ width: bigBoxWidth, height: bigBoxHeight }">
      <div class="img-box" v-if="bannerList.length">
        <div
          v-for="(item, index) in bannerList"
          :key="item.img"
          class="show-box"
          :style="{
            transform: 'translateX(' + translate + '%)',
            transition: tsion ? 'all 0.5s' : 'none',
          }"
        >
          <img
            :src="item.img"
            alt=""
            @click="jump(item.url)"
            :style="{ width: bigBoxWidth }"
          />
          <div
            class="show-box-text"
            :style="{ top: item.top, left: item.left }"
          >
            <h1
              v-for="(i) in item.title"
              :key="i"
              :style="{
                marginTop: index == 1 ? '-26px' : '',
                color: types == 'product' ? '#12A480' : '',
              }"
            >
              {{ i }}
            </h1>
            <div
              v-for="(k) in item.list"
              :key="k"
              style="font-size: 20px"
            >
              {{ k }}
            </div>
            <div
              v-if="index == 4"
              style="display: flex; font-size: 26px; font-weight: 500"
            >
              <div style="writing-mode: vertical-rl">优选好材真材实料</div>
              <div style="writing-mode: vertical-rl">好品质才有好效果</div>
            </div>
          </div>
        </div>
      </div>
      <div class="arrowhead-box" v-show="showNext">
        <span @click="last"></span>
        <span @click="next"></span>
      </div>
      <div class="swiper-box" ref="swiper">
        <span
          v-for="(item, index) in bannerList"
          :key="index"
          @click="swiper(index)"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bigBoxWidth: {
      type: String,
      default: "100vw",
    },
    bigBoxHeight: {
      type: String,
      default: "800px",
    },
    showNext: {
      type: Boolean,
      default: false,
    },
    bannerList: {
      type: Array,
      default: () => {},
    },
    types: {
      type: String,
      default: "index",
    },
  },
  data() {
    return {
      translateX: 0,
      tsion: true,
    };
  },
  methods: {
    // 上一张
    last() {
      this.translateX--;
      this.tsion = true;
      if (this.translateX < 0) {
        setTimeout(() => {
          this.tsion = false;
          this.translateX = this.bannerList.length - 1;
        }, 100);
      }
    },
    // 下一张
    next() {
      this.translateX++;
      this.tsion = true;
      if (this.translateX > this.bannerList.length - 1) {
        setTimeout(() => {
          this.tsion = false;
          this.translateX = 0;
        }, 100);
      }
    },
    swiper(i) {
      this.translateX = i;
    },
    jump(e) {
      if (!e) return;
      this.$router.push(e);
    },
  },
  mounted() {
    setInterval(() => {
      this.next();
    }, 3000);
  },
  computed: {
    translate() {
      return -this.translateX * 100;
    },
  },
  watch: {
    translateX: {
      handler(val) {
        let a = this.$refs.swiper.querySelectorAll("span");
        a.forEach((element) => {
          element.style.width = "12px";
        });

        if (this.translateX < 0) {
          val = this.bannerList.length - 1;
        }
        if (this.translateX > this.bannerList.length - 1) {
          val = 0;
        }
        a[val].style.width = "100px";
      },
    },
  },
};
</script>

<style>
.big-box {
  position: relative;
}

.img-box {
  height: 100%;
  overflow: hidden;
  display: flex;
}

.show-box {
  height: 100%;
  transition: all 0.5s;
}
.show-box-text {
  position: absolute;
  top: 218px;
  left: 20%;
}
.show-box img {
  height: 100%;
  object-fit: contain;
}

.arrowhead-box {
  position: absolute;
  top: 40%;
  float: left;
  width: 100%;
  height: 50px;
}

.arrowhead-box span {
  float: left;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50px;

  background-color: rgba(0, 0, 0, 0.4);
  background-position: 6px 14px;
  cursor: pointer;
  opacity: 0.5;
  position: relative;
}
.arrowhead-box span::after {
  position: absolute;
  left: 20%;
  top: 22%;
  width: 100%;
  height: 100%;
  content: "";
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/last.png") no-repeat;
}
.arrowhead-box span:nth-child(2) {
  float: right;
  transform: rotate(180deg);
}

.arrowhead-box span:hover {
  opacity: 1;
}

.swiper-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-box span {
  float: left;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.swiper-box span:nth-child(1) {
  width: 100px;
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";


import contact from "../views/pages/contact.vue";
import ppdt from "../views/pages/ppdt.vue";
import pptx from "../views/pages/pptx.vue";
import product from "../views/pages/product.vue";
import productInfo from "../views/pages/productInfo.vue";
import zj from "../views/pages/zj.vue";
import zhizuo from "../views/pages/zhizuo.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "index",
		component: index,
		meta: {
			title: "首页",
		},
	},
	{
		path: "/contact",
		name: "contact",
		component: contact,
		meta: {
			title: "联系我们",
		},
	},
	{
		path: "/ppdt/:types",
		name: "ppdt",
		component: ppdt,
		meta: {
			title: "品牌动态",
		},
	},
	{
		path: "/pptx/:types",
		name: "pptx",
		component: pptx,
		meta: {
			title: "品牌体系",
		},
	},
	{
		path: "/product",
		name: "product",
		component: product,
		meta: {
			title: "产品中心",
		},
	},
	{
		path: "/productInfo",
		name: "productInfo",
		component: productInfo,
		meta: {
			title: "产品中心",
		},
	},
	{
		path: "/zj/:types",
		name: "zj/:types",
		component: zj,
		meta: {
			title: "走进九宫爱",
		},
	},
	{
		path: "/zhizuo",
		name: "zhizuo",
		component: zhizuo,
		meta: {
			title: "制艾工艺",
		},
	},

];
// title

const router = new VueRouter({
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});

<template>
  <div>
    <dheader></dheader>
    <div class="product">
      <div class="productDiv">
        <h1>产品中心</h1>
      </div>
    </div>
    <div class="subMain">
      <div style="width: 60vw; margin: 0 auto">
        <lunb
          bigBoxWidth="60vw"
          bigBoxHeight="600px"
          :showNext="true"
          :bannerList="bannerList"
          types="product"
        />
      </div>
      <div class="xueyue" style="width: 60vw">
        <div
          class="xueyue-item"
          v-for="(item, index) in list"
          :key="index"
          @click="jump(item.url)"
        >
          <img v-lazy="item.img" alt="" />
          <div style="margin-top: 20px">{{ item.text }}</div>
        </div>
        <div style="width: 360px; height: 1; opacity: 0"></div>
        <div style="width: 360px; height: 1; opacity: 0"></div>
        <div style="width: 360px; height: 1; opacity: 0"></div>
      </div>
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import dheader from "../../components/dheader.vue";
import lunb from "../../components/lunb.vue";
export default {
  components: {
    footer2,
    dheader,
    lunb,
  },
  data() {
    return {
      list: [
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product-1.png",
          text: "九宫艾智能艾灸盒",
          url: "/productInfo?id=1",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product-2.png",
          text: "九宫艾艾灸柱",
          url: "/productInfo?id=2",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product-3.png",
          text: "九宫艾艾灸贴",
          url: "/productInfo?id=3",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product-4.png",
          text: "艾草足浴包",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product-5.png",
          text: "暖敷眼罩",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/product-6.png",
          text: "艾灸贴",
        },
      ],
      bannerList: [
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/banner-1.png",
          title: ["九宫艾智能艾灸盒"],
          list: ["明火灸就是透", "传承中医古法明火悬灸技术"],
          top: "34%",
          left: "15%",
          url: "/productInfo?id=3",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/banner-2.png",
          title: ["九宫艾艾灸贴"],
          list: ["艾草精华 深层渗透 "],
          top: "45%",
          left: "20%",
          url: "/productInfo?id=1",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/product/banner-3.png",
          title: ["九宫艾艾灸柱"],
          list: ["家用艾灸随身灸 一撕一贴轻松上手 "],
          top: "38%",
          left: "20%",
          url: "/productInfo?id=2",
        },
      ],
    };
  },
  methods: {
    jump(e) {
      if (!e) return;
      this.$router.push(e);
    },
  },
};
</script>

<style scoped>
.main {
  width: 61%;
  margin: 60px auto 30px;
}
.product {
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/lvdi.png")
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 297px;
}
.product .productDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.xueyue {
  width: 1300px;
  margin: 60px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.xueyue-item {
  padding-bottom: 20px;
  text-align: center;
  width: 360px;
  margin-bottom: 30px;
}
.xueyue-item img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  background: #f1f1f1;
}
</style>

<template>
  <div>
    <dheader></dheader>
    <div class="product">
      <div class="productDiv">
        <h1>{{ types == 0 ? "企业动态" : "行业动态" }}</h1>
      </div>
    </div>
    <div class="subMain">
      <div class="subTitle">
        <div :class="types == 0 ? 'subTitle-active' : ''" @click="bindTypes(0)">
          企业动态
        </div>
        <div :class="types == 1 ? 'subTitle-active' : ''" @click="bindTypes(1)">
          行业动态
        </div>
      </div>
      <div v-show="lookInfo">
        <div class="NewsDetails">
          <div class="NewsDetails_cont">
            <div class="NewsDetails_cont_title">
              <h1>小小一株艾草，撬动百亿大产业</h1>
              <div class="NewsDetails_cont_title_span">
                <span>2020-8-31 &ensp;&ensp; </span>
                <span>发布者：九宫艾</span>
              </div>
            </div>
            <div class="NewsDetails_cont_text">
              <p>伏牛苍苍，丹水泱泱。奇山秀水的南阳，滋养着艾草蓬勃生长。</p>
              <div>
                一株小小的“艾草”，撬动百亿产业变身“致富草”，已成长为南阳特色产业之一。
              </div>
              <p>
                产品市场占有率全国第一、艾草种植加工企业1551家、艾产业年产值150亿元��艾草正以前所未有的发展势头，在这片古老的土地上焕发新的生机。
              </p>
              <p>
                目前，“南阳艾”全产业链体系初步形成，涵盖一、二、三产。围绕艾产业链，如何进一步延链、补链、强链？南阳已有了新的探索和成效
              </p>
              <p>“世界艾乡”发轫之初</p>
              <p>
                8月下旬，正值艾草第二茬收割季。肖师傅的地里，伴着艾草的馨香味，几辆收割机忙碌作业，将艾草不断卷入，而
                “吐出”时，艾草已成型成块，被打包装车，送往企业陈放。“一亩地能产900公斤，一年能收三茬，你说我们干得能不
                起劲儿吗？”下午地里日头正烈，肖师傅乐呵呵地说。肖师傅管理着三个大型野生艾草地，都属于南阳仙草健康集
                团有限公司。
              </p>
              <p>
                作为南阳知名企业之一，该公司从一个小作坊起步，用了12年时间，发展成为一个集艾草种植、艾草粗精加工、灸
                材生产销售、艾灸器具产品研发、艾绒艾条艾柱艾饼OEM贴牌代工、艾灸养生馆为一体的艾草体系完整、员工规模
                上千人的现代化企业。从小作坊起步，逐渐做大做强，这是南阳大多数艾产业企业发展的必经之路。
              </p>
              <p>
                南阳地处亚热带和暖温带的过渡地带，是艾草绝佳适生地。20世纪90年代末，南阳北郊注册了第一家艾草加工企
                业，生产艾条、艾绒，这成为南阳艾草产业的发轫。受该企业带动和影响，南阳人抓住商机，纷纷投身艾产业，逐渐
                在中原大地上遍地开花。
              </p>
              <p>
                “南阳艾”根植于医圣张仲景故里，生长于黄金纬度带的伏牛山南，是取得国家地理标志的特色产业名片。“南阳
                艾”现有种植面积约30万亩，占全球艾草总量80%。
              </p>
              <p>
                尽管艾草种植面积可观，但面对供不应求的市场需求，各企业往往除了自己的种植基地，还要从市场采购一部分。
                以南阳仙草健康集团有限公司为例，每年艾草用量在3万吨左右，其中野生艾草占据六成以上。自2010年开始，该
                公司在桐柏、邓州、南召、唐河、内乡等地十余个乡镇大规模设立野生艾草收购点，带动了近万名农民增收，端午节
                前后艾叶成熟期能够带动农民创收近万元。
              </p>
              <p>
                发展到2023年，南阳艾草种植面积超30万亩，野生艾草开发利用12万吨；艾草种植、加工企业和服务机构近2000
                家，在艾产业链上有约30万农民参与其中；年产值上亿的企业6家，南阳艾产业年产值150亿元。
              </p>
            </div>
            <!-- <div class="NewsDetails_cont_img">
              <img
                src="../../assets/img/newsDetail/摄图网_401521307_banner.png"
                alt=""
              />
            </div> -->
            <div class="NewsDetails_cont_btm">
              <div></div>
              <p @click="bindBack()">返回列表 ></p>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!lookInfo">
        <div>
          <div class="news_cont_right_text" v-show="types == 0">
            <div
              class="news_cont_right_text_item"
              v-for="(item, index) in newList"
              :key="index"
              @click="newsArticle(index)"
            >
              <div>
                <img v-lazy="item.img" alt="" />
              </div>
              <div>
                <h1>{{ item.title }}</h1>
                <p>{{ item.time }}</p>
                <p>
                  {{ item.text }}
                </p>
                <div class="liaoj" @click="bindInfo(item)">了解更多 ></div>
              </div>
            </div>
          </div>
          <div class="news_cont_right_text" v-show="types == 1">
            <div
              class="news_cont_right_text_item"
              v-for="(item, index) in hangye"
              :key="index"
              @click="newsArticle(index)"
            >
              <div>
                <img v-lazy="item.img" alt="" />
              </div>
              <div>
                <h1>{{ item.title }}</h1>
                <p>{{ item.time }}</p>
                <p>
                  {{ item.text }}
                </p>
                <div class="liaoj" @click="bindInfo(item)">了解更多 ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="feny">
          <div
            v-for="(item, index) in 10"
            :key="index"
            :class="index + 1 == page ? 'fenyA' : ''"
            @click="bindFeny(index)"
          >
            {{ index + 1 }}
          </div>
        </div>
      </div>
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import dheader from "../../components/dheader.vue";
export default {
  components: {
    footer2,
    dheader,
  },
  data() {
    return {
      types: 0,
      page: 1,
      newList: [
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/dongtai1.png",
          title: "湿热体质之九宫艾艾灸疗法",
          time: "2020.08.30",
          text: "湿热体质是一种内环境不清洁，又湿又热，湿热氰氲，排泄不畅的体质。内外皆显得“浊，对女性美容困扰很大。这种体质的人养生就要注意“干燥，既要保证生活的外环境干爽清",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/dongtai2.png",
          title: "用九宫艾艾灸是疏通经络的好方式",
          time: "2020.08.25",
          text: "《黄帝内经》记载：“经脉者，决生死，处百病，调虚实，不可不通。”意思是说，“经络”是运行气血、联系脏腑和体表及全身各部的通道，是人体功能的调控系统，与健康息息相关。",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/dongtai3.png",
          title: "用九宫艾艾灸是疏通经络的好方式",
          time: "2021-05-30",
          text: "《黄帝内经》记载：“经脉者，决生死，处百病，调虚实，不可不通。”意思是说，“经络”是运行气血、联系脏腑和体表及全身各部的通道，是人体功能的调控系统，与健康息息相关。",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/dongtai4.png",
          title: "用九宫艾艾灸是疏通经络的好方式",
          time: "2020.08.25",
          text: "《黄帝内经》记载：“经脉者，决生死，处百病，调虚实，不可不通。”意思是说，“经络”是运行气血、联系脏腑和体表及全身各部的通道，是人体功能的调控系统，与健康息息相关。",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/dongtai5.png",
          title: "用九宫艾艾灸是疏通经络的好方式",
          time: "2021-05-30",
          text: "《黄帝内经》记载：“经脉者，决生死，处百病，调虚实，不可不通。”意思是说，“经络”是运行气血、联系脏腑和体表及全身各部的通道，是人体功能的调控系统，与健康息息相关。",
        },
      ],
      hangye: [
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/dongtai1.png",
          title: "小小一株艾草，撬动百亿大产业",
          time: "2020.08.30",
          text: "伏牛苍苍，丹水泱泱。奇山秀水的南阳，滋养着艾草蓬勃生长。一株小小的“艾草”，撬动百亿产业变身“致富草”，已成长为南阳特色产业之一。",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/dongtai2.png",
          title: "用九宫艾艾灸是疏通经络的好方式",
          time: "2020.08.25",
          text: "《黄帝内经》记载：“经脉者，决生死，处百病，调虚实，不可不通。”意思是说，“经络”是运行气血、联系脏腑和体表及全身各部的通道，是人体功能的调控系统，与健康息息相关。",
        },
        {
          img: "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/dongtai3.png",
          title: "用九宫艾艾灸是疏通经络的好方式",
          time: "2021-05-30",
          text: "《黄帝内经》记载：“经脉者，决生死，处百病，调虚实，不可不通。”意思是说，“经络”是运行气血、联系脏腑和体表及全身各部的通道，是人体功能的调控系统，与健康息息相关。",
        },
      ],
      lookInfo: false,
    };
  },
  mounted() {
    this.types = this.$route.params.types;
  },
  watch: {
    "$route.params.types"(old) {
      this.types = old;
    },
  },
  methods: {
    bindFeny(index) {
      this.page = index + 1;
    },
    bindTypes(e) {
      this.types = e;
    },
    bindInfo() {
      this.lookInfo = true;
    },
    bindBack() {
      this.lookInfo = false;
    },
  },
};
</script>

<style scoped>
.fenyA {
  background: #3e3a39;
  color: #fff;
}
.feny {
  display: flex;
  margin: 30px auto;
  justify-content: center;
}
.feny div {
  border: 1px solid #999;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-right: 20px;
}
.product {
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/lvdi.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 297px;
}
.product .productDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.news_cont_right_text {
  width: 100%;
  background: #ffffff;
  margin-top: 68px;
}
.news_cont_right_text_item {
  width: 100%;
  border-bottom: 1px solid #d7d7d7;
  padding: 36px 0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.news_cont_right_text_item img {
  width: 300px;
  height: 200px;
  margin-right: 20px;
}
.news_cont_right_text_item h1 {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.news_cont_right_text_item p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  line-height: 20px;
}

.liaoj {
  margin-top: 20px;
  width: 110px;
  height: 25px;
  line-height: 25px;
  border-radius: 100px;
  text-align: center;
  border: 1px solid #12a480;
  color: #12a480;
  font-size: 12px;
}

/*  */
.NewsDetails_cont {
  width: 100%;
  margin: 0 auto 30px;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  width: 1000px;
  margin: 40px 100px;
}
.NewsDetails_cont_text div {
  margin-left: 90px;
  font-size: 24px;
  font-weight: bold;
}
.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}
.NewsDetails_cont_footer {
  padding: 0 40px;

  line-height: 68px;
  width: 1120px;
  height: 68px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>
<template>
  <div>
    <dheader></dheader>
    <div class="product">
      <div class="productDiv">
        <h1 v-if="types == 0">品牌简介</h1>
        <h1 v-if="types == 1">品牌文化</h1>
        <h1 v-if="types == 2">品牌实力</h1>
      </div>
    </div>
    <div class="subMain">
      <div class="subTitle">
        <div :class="types == 0 ? 'subTitle-active' : ''" @click="bindTypes(0)">
          品牌简介
        </div>
        <div :class="types == 1 ? 'subTitle-active' : ''" @click="bindTypes(1)">
          品牌文化
        </div>
        <div :class="types == 2 ? 'subTitle-active' : ''" @click="bindTypes(2)">
          品牌实力
        </div>
      </div>
      <div v-show="types == 0">
        <div class="NewsDetails">
          <div class="NewsDetails_cont">
            <div class="NewsDetails_cont_title">
              <h1>品牌简介</h1>
            </div>
            <div class="NewsDetails_cont_text">
              <p>
                九宫艾是深圳巨莱康将康科技有限公司旗下的全产业链艾草品牌，涵盖科研创新、生产销售、健康养生、品牌运营、灸师培训等诸多领域。用创新
                性理念和创造性思维，整合中国艾行业优势资源，以创新工艺，创新产品，做好中国好艾。独创“九宫按摩法”、“九宫八卦步”，与三位一体的产品
                体系相结合，强身健体，健康养生，从内到外综合疗养。
              </p>
              <div style="margin: 0 auto">
                <div>
                  <img
                    style="width: 100%"
                    src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai4.png"
                    alt=""
                  />
                </div>
                <div style="display: flex">
                  <img
                    style="width: 32.33%; margin-right: 1%"
                    src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai5.png"
                    alt=""
                  />
                  <img
                    style="width: 32.33%; margin-right: 1%"
                    src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai6.png"
                    alt=""
                  />
                  <img
                    style="width: 33.33%"
                    src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai7.png"
                    alt=""
                  />
                </div>
              </div>
              <p>
                九宫艾是以艾产业全链条为主核的科技型企业，总部位于中国科技之都深圳。核心团队来自知名科研院所、大学及企业，是一站式智慧健康科技
                解决方案服务平台。
              </p>
              <p>
                九宫艾依托现拥有的万亩艾草种植基地，建立起集产品研发生产、行业标准制定、电商营销管理、人工智能研发、大数据分析、智能终端、门店连
                锁全产业生态链，发展成为艾草产业的头部企业，并实现集团化运营。
              </p>
              <p>
                九宫艾规划“以艾产业建平台,以平台建生态”的战略发展方向，实施“智慧平台+智能终端+千城万店+无界生态”的产业布局，实现“千城万店五
                年千亿”目标，带动百万人就业，惠及千万家庭。
              </p>
              <p>
                立足大湾区，辐射全中国，共建、共享、共富，创写人工智能健康、消费新时代！
              </p>
            </div>
            <div class="NewsDetails_cont_btm">
              <div></div>
              <!-- <p @click="bindBack()">返回列表 ></p> -->
            </div>
          </div>
        </div>
      </div>
      <div v-show="types == 1">
        <div class="ppwh-zzgy">
          <img
            class="ppwh-zzgy-img"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai1.png"
            alt=""
          />
          <div class="ppwh-zzgy-rt">
            <div class="ppwh-zzgy-rt-item">
              <h1 style="color: #12a480">品牌使命</h1>
              <h3>弘扬千年艾文化</h3>
              <h3>匠心打造产业链，健康服务每一人</h3>
            </div>
          </div>
        </div>
        <div class="ppwh-zzgy" style="margin: 30px 0">
          <div class="ppwh-zzgy-rt">
            <div class="ppwh-zzgy-rt-item">
              <h1 style="color: #12a480">品牌愿景</h1>
              <h3>成为艾草产业</h3>
              <h3>垂直细分领域的头部企业</h3>
            </div>
          </div>
          <img
            class="ppwh-zzgy-img"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai2.png"
            alt=""
          />
        </div>
        <div class="ppwh-zzgy">
          <img
            class="ppwh-zzgy-img"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai3.png"
            alt=""
          />
          <div class="ppwh-zzgy-rt">
            <div class="ppwh-zzgy-rt-item">
              <h1 style="color: #12a480">品牌价值观</h1>
              <h3>用好艾，温暖爱</h3>
              <h3>用艾连接你我</h3>
            </div>
          </div>
        </div>
      </div>
      <div v-show="types == 2">
        <div class="ppwh-zzgy">
          <img
            class="ppwh-zzgy-img"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai8.png"
            alt=""
          />
          <div class="ppwh-zzgy-rt">
            <div class="ppwh-zzgy-rt-item">
              <h1 style="color: #12a480">供应链</h1>
              <div class="ppwh-item-text" style="margin-top: 30px">
                九宫艾专注于艾草的创新研发，总部巨莱康同南阳国医仲景艾
                草产业集团、南阳仙草药业有限公司等大型企业展开深度合作。
                拥有生产员工 500 余人，8000 吨艾草原料的储存能力和1500
                吨成品的仓储能力。
              </div>
            </div>
          </div>
        </div>
        <div class="ppwh-zzgy" style="margin: 30px 0">
          <div class="ppwh-zzgy-rt">
            <div class="ppwh-zzgy-rt-item">
              <h1 style="color: #12a480">专业管理</h1>
              <div class="ppwh-item-text" style="margin-top: 30px">
                形成了标准化、专业化的管理体系，环境管理程序、田间管理规
                范。设备齐全，拥有技术先进的自动生产线，艾柱自动切割机，微
                电脑自动包装机等先进生产设备。
              </div>
            </div>
          </div>
          <img
            class="ppwh-zzgy-img"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai9.png"
            alt=""
          />
        </div>
        <div class="ppwh-zzgy">
          <img
            class="ppwh-zzgy-img"
            src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pinpai10.png"
            alt=""
          />
          <div class="ppwh-zzgy-rt">
            <div class="ppwh-zzgy-rt-item">
              <h1 style="color: #12a480">现代化生产</h1>
              <div class="ppwh-item-text" style="margin-top: 30px">
                拥有占地面积 20000 平方米的大型艾制品加工基地，产品甄选
                三年陈艾，精细化制绒加工。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import dheader from "../../components/dheader.vue";
export default {
  components: {
    footer2,
    dheader,
  },
  data() {
    return {
      types: 0,
    };
  },
  watch: {
    "$route.params.types"(old) {
      this.types = old;
    },
  },
  mounted() {
    this.types = this.$route.params.types;
  },
  methods: {
    bindTypes(e) {
      this.types = e;
    },
  },
};
</script>

<style scoped>
.product {
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/lvdi.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 297px;
}
.product .productDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*  */

.NewsDetails_cont {
  width: 100%;
  margin: 0 auto 30px;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
}
.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}
.NewsDetails_cont_footer {
  padding: 0 40px;

  line-height: 68px;
  width: 1120px;
  height: 68px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
/*  */

.ppwh-zzgy {
  display: flex;
  justify-content: space-between;
}
.ppwh-zzgy h3 {
  color: #999;
}
.ppwh-zzgy-img {
  width: 50%;
  height: 100%;
}
.ppwh-zzgy-rt {
  background: #ebebea;
  width: 50%;
  position: relative;
}
.ppwh-zzgy-rt-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ppwh-item-text {
  line-height: 26px;
  font-size: 13px;
}
</style>
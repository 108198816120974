import { render, staticRenderFns } from "./dheader.vue?vue&type=template&id=4f5fd58c&"
import script from "./dheader.vue?vue&type=script&lang=js&"
export * from "./dheader.vue?vue&type=script&lang=js&"
import style0 from "./dheader.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
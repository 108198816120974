<template>
  <!-- star -->

  <div class="homepage_wrap">
    <!-- 导航栏 -->
    <dheader></dheader>

    <lunb :bannerList="bannerList"></lunb>
    <div class="video">
      <video
        id="myVideo"
        src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/media/jga_introduce.mp4"
        loop
        style="width: 100%; height: 100%; object-fit: fill"
        @click="bindPlay"
      ></video>
      <div class="video-mask" v-if="isPlay">
        <div class="video-mask-text">
          <h1 style="color: #12a480">品牌介绍</h1>
          <h2>Brand introduction</h2>
          <div class="ppwh-item-text" style="margin-top: 20px">
            九宫艾：选好艾，用好艾
          </div>
          <div class="ppwh-item-text">
            医圣故里3000亩艾草种植基地，提供优质原料，<br />
            精选三年陈艾，塑造优质口碑。
          </div>
          <div
            class="ppwh-item-but"
            style="color: #fff; border-color: #fff"
            @click="funRouter('/zj/0')"
          >
            查看详情
          </div>
        </div>
        <img
          @click="bindPlay"
          class="video-play"
          src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/play.png"
        />
      </div>
      <!-- <img
        @click="bindPlay"
        v-if="isPlay "
        class="video-play"
        src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/pause.png"
      /> -->
    </div>

    <div class="ppwh">
      <div class="ppwh-item">
        <div class="ppwh-item-rt">
          <h1 style="color: #12a480">品牌文化</h1>
          <h2 style="color: #999; margin-top: -22px; margin-bottom: -15px">
            Brand cultrue
          </h2>
          <div class="ppwh-item-text" style="margin-top: 30px">
            增强过敏身体素质，弘扬中医养生文化
          </div>
          <div class="ppwh-item-text">
            独创“九宫按摩法”、“九宫八卦步”日常调理理论，坚持传承、创新、健康为品牌宗旨。
          </div>
          <div class="ppwh-item-text">
            九宫：心为绛霄宫，肾为丹元宫，肝为兰台宫，肺为尚书宫，脾为黄庭宫，胆为天霐宫，小肠为玄灵宫，大肠为木灵宫，膀胱为玉房宫。
          </div>
          <div class="ppwh-item-but" @click="funRouter('/zj/1')">查看详情</div>
        </div>
      </div>

      <div class="ppwh-zzgy">
        <img
          class="ppwh-zzgy-img"
          src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/home-2.png"
          alt=""
        />
        <div class="ppwh-zzgy-rt" style="position: relative; top: 0; right: 0">
          <h1 style="color: #12a480">制艾工艺</h1>
          <h2 style="color: #999; margin-top: -22px; margin-bottom: -15px">Technology</h2>
          <div class="ppwh-item-text" style="margin-top: 30px">
            精准制作流程，天时、地利、人和，为品质护航
          </div>
          <div class="ppwh-item-text">天时：端午时节收割，集纯阳之气，汇天然药性</div>
          <div class="ppwh-item-text">地利：伏牛山深处宛艾，通风处自然晾晒，精心储存</div>
          <div class="ppwh-item-text">
            人和：因地制宜、因时制宜，甄选三年陈艾，八大传统工艺筛选研磨
          </div>
          <div class="ppwh-item-but" @click="funRouter('/zhizuo')">查看详情</div>
        </div>
      </div>
      <div class="ppwh-zzgy">
        <div class="ppwh-zzgy-rt">
          <h1 style="color: #12a480">健康学院</h1>
          <h2 style="color: #999; margin-top: -22px; margin-bottom: -15px">
            School of health
          </h2>
          <div class="ppwh-item-text" style="margin-top: 30px">
            人生百味皆燃烬，悠悠千载艾飘香
          </div>
          <div class="ppwh-item-text">若说“杏”乃中医之花，那“艾”便是中医之草了。</div>
          <div class="ppwh-item-text">
            艾草的药用在我国至少有3000多年的历史，艾灸更是中医文化的精髓之一，了解“艾”才能用好“艾”。
          </div>
          <div class="ppwh-item-but" @click="funRouter('/pptx/2')">查看详情</div>
        </div>
        <img
          class="ppwh-zzgy-img"
          src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/home-3.png"
          alt=""
        />
      </div>
    </div>

    <div class="product">
      <h1>Product</h1>
      <h2>产品</h2>
      <div>
        贴、涂、灸，三位一体循环调理表皮、腠理、脏腑，由表入里，外调肌肤，内通经络
      </div>
      <div class="product-img">
        <img
          @click="funRouter('/productInfo?id=1')"
          src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/product-1.png"
          alt=""
        />
        <img
          @click="funRouter('/productInfo?id=2')"
          src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/product-2.png"
          alt=""
        />
        <img
          @click="funRouter('/productInfo?id=3')"
          src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/product-3.png"
          alt=""
        />
        <img
          @click="funRouter('/productInfo?id=4')"
          src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/product-4.png"
          alt=""
        />
      </div>
    </div>
    <!-- footer -->
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import lunb from "../components/lunb.vue";
import footer2 from "../components/footer.vue";
import dheader from "../components/dheader.vue";
export default {
  name: "index",
  components: {
    footer2,
    lunb,
    dheader,
  },
  data() {
    return {
      windowWd: 0,
      navList: [
        { title: "首页", url: "/" },
        { title: "走进九宫艾", url: "/jituangaikuo" },
        { title: "好艾多", url: "/chanye" },
        { title: "产品中心", url: "/xinwen" },
        { title: "品牌体系", url: "/zhaopinxiangqing0_0" },
        { title: "品牌动态", url: "/lianxi" },
        { title: "联系我们", url: "/zhaopinxinxi0" },
      ],
      isPlay: true,
      hidePlayImg: false,
      vid: {},
      bannerList: [
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/banner-1.png",
          title: ["国货新品牌"],
          list: ["new domestic products", "new intelligence new technology"],
          top: "218px",
          left: "20%",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/banner-2.png",
          title: ["蒸汽暖敷眼罩", "给眼睛做个SPA"],
          list: ["温柔入睡香甜枕", " 美眼润眼元气满满每一天"],
          top: "38%",
          left: "22%",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/banner-3.png",
          title: ["九宫艾AI数字康养生活馆"],
          list: ["JIUGONGAI AI", "digital health club"],
          top: "218px",
          left: "20%",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/banner-4.png",
          title: ["九宫艾系列产品"],
          list: [""],
          top: "218px",
          left: "20%",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/banner-5.png",
          title: [],
          list: [""],
          shu: ["", ""],
          top: "133px",
          left: "43%",
        },
      ],
    };
  },
  mounted() {
    this.windowWd = window.innerWidth;
    // windowWd
    var vid = document.getElementById("myVideo");
    vid.onpause = (res) => {
      console.log("暂停", res);
      this.isPlay = true;
    };
    vid.onplay = (res) => {
      console.log("开始播放", res);
      this.isPlay = false;
    };
    this.vid = vid;
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
  },
  methods: {
    bindPlay() {
      console.log(this.isPlay);
      if (this.isPlay) {
        this.vid.play();
        this.isPlay = false;
      } else {
        this.vid.pause();
        this.isPlay = true;
      }
    },

    newsArticle(index) {
      this.$router.push("/newsxiangqing" + index);
    },
    funRouter(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
@charset "utf-8";
.video {
  width: 100vw;
  height: 800px;
  position: relative;
}
.video-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  top: 0;
  left: 0;
  color: #fff;
}
@keyframes ttop {
  0% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0);
  }
}
.video-mask-text {
  position: absolute;
  top: 35%;
  left: 25%;
  animation: ttop 1s;
}
.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 128px;
  height: 128px;
}
.ppwh {
  width: 1216px;
  margin: 114px auto 84px;
}
.ppwh-item {
  position: relative;
  width: 100%;
  height: 428px;
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/home-1.png")
    no-repeat;
  background-size: 100% 100%;
  margin-bottom: 80px;
}
.ppwh-item-rt {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.55);
  padding: 14px 100px 36px 90px;
  width: 350px;
}
.ppwh-item-text {
  line-height: 26px;
  font-size: 13px;
}
.ppwh-item-but {
  border-radius: 100px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #999;
  color: #999;
  margin-top: 20px;
}
.ppwh-zzgy {
  display: flex;
  justify-content: space-between;
}
.ppwh-zzgy-img {
  width: 50%;
  height: 100%;
}
.ppwh-zzgy-rt {
  background: rgba(255, 255, 255, 0.55);
  width: 400px;
  margin: auto;
}
.product {
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/productbg.png")
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  padding: 132px 0 127px;
}
.product-img {
  margin-top: 56px;
}
.product img {
  width: 294px;
  height: 204px;
  margin-right: 30px;
}

/*==========================Reset_End===========================*/

.homepage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

/* title */

.banner_title_wrap {
  width: 100%;
  position: absolute;
  bottom: 290px;
  /* border: 1px solid yellow; */
}

.banner_title_center {
  /* border: 1px solid white; */
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  font-size: 90px;
  font-family: AlibabaPuHuiTi-Bold, AlibabaPuHuiTi;
  font-weight: bold;
  color: #ffffff;
  line-height: 124px;
  letter-spacing: 1px;
}

.banner_title_center div {
  padding-top: 14px;
  font-size: 20px;
  font-family: Helvetica;
  line-height: 24px;
  letter-spacing: 4px;
}

.banner_title_center div span {
  margin: 0 18px;
}
/* news */
.news {
  width: 100vw;
  height: 801px;
  background: #f8f8f8;
}
.news_cont {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.news_cont_left {
  margin-top: 89px;
}
.news_cont_left_img img {
  width: 588px;
  height: 353px;
  margin-top: 41px;
}
.news_cont_left_text {
  padding-top: 27px;
  padding-left: 40px;
  width: 548px;
  height: 150px;
  background-color: #ffffff;
}
.news_cont_left_text h1 {
  font-size: 18px;
  font-weight: 500;
  color: #23337f;
}
.news_cont_left_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  margin-top: 10px;
}
.news_cont_right {
  width: 588px;
  margin-top: 103px;
}
.news_cont_right_ul ul {
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 22px;
  float: right;
  display: flex;
}
.news_cont_right_ul ul a {
  font-size: 16px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 22px;
  float: right;
  display: flex;
}
.news_cont_right_ul ul li:hover {
  color: #23337f;
}
.news_cont_right_ul ul span {
  margin: 0 23px 0 24px;
}
/* 集团产业 */
.GroupIndustry {
  width: 100vw;
  height: 800px;
  background: #ffffff;
}
.GroupIndustry_cont {
  width: 1200px;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
}
.GroupIndustry_cont_left_title {
  font-size: 36px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #444444;
  line-height: 50px;
  margin: 84px 0 40px 0;
}
.GroupIndustry_cont_left_img_f {
  width: 793px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.GroupIndustry_cont_left_img {
  margin-bottom: 24px;

  position: relative;
}
.GroupIndustry_cont_left_img img:first-child {
  width: 384px;
  height: 261px;
  background: linear-gradient(180deg, rgba(0, 100, 255, 0) 0%, #000b6e 100%);
}
.GroupIndustry_cont_left_img2 {
  position: absolute;
  left: 0;
  bottom: 0;
}

.GroupIndustry_cont_left_text {
  position: absolute;
  left: 32px;
  bottom: 18px;
}
.GroupIndustry_cont_left_text p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 10px;
}
.GroupIndustry_cont_left_text span {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}
.GroupIndustry_cont_right {
  position: relative;
  margin-top: 98px;
}
.GroupIndustry_cont_right_title {
  float: right;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 22px;
  margin-bottom: 54px;
}

.GroupIndustry_cont_right_img {
  width: 384px;
  height: 546px;
}
.GroupIndustry_cont_right_img2 {
  position: absolute;
  bottom: 24px;
}
.GroupIndustry_cont_right_text {
  position: absolute;
  left: 45px;
  bottom: 45px;
}
.GroupIndustry_cont_right_text p {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
}
.GroupIndustry_cont_right_text span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}

/* 合作伙伴 */
.dropbtn {
  background-color: #fff;
  color: rgb(12, 11, 11);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  top: 11px;
  left: 394px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  text-align: center;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 95px;
  min-height: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: rgb(6, 6, 6);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>

<template>
  <div>
    <dheader></dheader>
    <div class="product">
      <div class="productDiv" v-if="types == '0'">
        <h1>线下门店</h1>
        <div style="text-align: left">
          <div>
            九宫艾在种植基地所在城市以及周边城市，已建成艾灸师培训实训基地，培训了一批专业的艾灸养生老师，还
          </div>
          <div>
            建有十数家品牌艾灸养生会馆。专业艾灸老师会详细普及艾草功能、使用方法等，举行健康养生体验活动，通过
          </div>
          <div>
            科学检测，以及中医理论辅导，让当地居民可以更加直观地了解到自己的身体状况。
          </div>
        </div>
      </div>
      <div class="productDiv" v-if="types == '1'">
        <h1>线上商城</h1>
        <div style="text-align: left">
          <div>
            九宫艾艾草产品已经入驻天猫、京东、淘宝、拼多多等大型电商平台，拥有强大网络营销体系，完善的
          </div>
          <div>
            终端连锁服务，包括形成了售前顾问、物流跟踪、售后服务等规范化管理。同时拥有将近200万注册用
          </div>
          <div>户的电商销售平台，以及专注中医养生、中医问诊知识的短视频平台。</div>
        </div>
      </div>
      <div class="productDiv" v-if="types == '2'">
        <h1>九宫艾健康学院</h1>
        <h1 style="margin-top: -25px">SCHOOL OF HEALTH</h1>
        <div style="text-align: left">
          <div>
            健康学院，聚焦百姓健康生活，健康养生，中医问诊，养生学习等一些和生活息息相关的健康常
          </div>
          <div>
            识。可以通过中医问诊、养生学习栏目或者中医理疗老师的视频了解艾草功效，艾灸知识等。
          </div>
        </div>
      </div>
    </div>
    <div class="subMain">
      <div class="subTitle">
        <div :class="types == 0 ? 'subTitle-active' : ''" @click="bindTypes(0)">
          线下门店
        </div>
        <div :class="types == 1 ? 'subTitle-active' : ''" @click="bindTypes(1)">
          线上市场
        </div>
        <div :class="types == 2 ? 'subTitle-active' : ''" @click="bindTypes(2)">
          健康学院
        </div>
      </div>

      <div v-show="lookInfo">
        <div class="NewsDetails">
          <div class="NewsDetails_cont">
            <div class="NewsDetails_cont_title">
              <h1>天凉脚先冷，要让身体暖起来， 可以时常这样做‼️</h1>
              <div class="NewsDetails_cont_title_span">
                <span>2020-8-31 &ensp;&ensp; </span>
                <span>发布者：九宫艾</span>
              </div>
            </div>
            <div class="NewsDetails_cont_text">
              <div style="text-align: center; margin-top: 20px">
                <img
                  style="width: 80%"
                  src=" https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/PkcPsz.png"
                  alt=""
                />
              </div>
              <p>
                随着深秋的脚步越来越近，寒意袭人，不少人已经将「泡脚养生」提上了日程。泡脚不仅能够帮助我们温暖身体，还能改善局部血液循环，促进新陈代谢。
              </p>
              <p>
                我国传统中医理论更是早有记载：“一年四季沐足，春天洗脚，开阳固脱；夏天洗脚，暑理可祛；秋天洗脚，肺润肠蠕；冬天洗脚，丹田湿灼”。可见，在不同的季节，泡脚能够对人体产生不同的作用。而在深秋时节，寒露后寒气渐渐袭人，泡脚更成为了养生的必备功课。
              </p>
              <p>
                当下，许多人在秋天仍然选择穿露脚踝的裤子和露脚后跟的鞋子，这样一来，寒气很容易从脚底侵入体内，甚至导致一些年轻人出现膝关节疼痛的情况。
              </p>
              <p>
                相信大家都有听过这样一谚语：“白露身不露，寒露脚不露”，这句谚语的背后却蕴含着“寒从脚起”的中医药文化知识。因为脚部是经络的起点，而脚部距离我们的心脏很远，血液供应较少，再加上脚部的脂肪层比较薄，所以它是最容易受到寒邪侵袭的部位。
              </p>
              <p>
                如果足部感到寒冷，那么人体的抵抗力就会下降，各种疾病也就有可能乘虚而入。轻则可能只是伤风感冒，重则可能会诱发慢性支气管炎、哮喘等呼吸系统疾病。所以，我们应该注重足部的保暖，不要让寒气有机可乘。
              </p>
              <p>
                正确的泡脚可以疏通经络、刺激足部穴位，温补下元、改善睡眠，是驱寒除湿的不错选择。而在泡脚时加入适当的中草药，能够更好地驱寒保暖、活血通经、温肾消肿等功效。比如艾叶、生姜偏温性，可以驱寒保暖；红花主要功效为活血通经、祛瘀止痛；桂皮可以起到温肾消肿作用；花椒则属祛寒类中药，善散阴寒之气，助元阳之不足。
              </p>
              <p>
                为了方便大家更好地体验泡脚养生的乐趣和效果，我们特别推荐九宫艾艾草足浴包。它内含艾草、生姜、红花、益母草4味中草药。不用熬煮直接泡，汤色浓郁，更适合现代年轻人忙碌的生活节奏。
              </p>
              <p>
                同时，艾草被誉为“百草之王”，具有温经散寒、活血通络的功效；生姜则能够温经散寒、活血通络；红花具有活血通经、祛瘀止痛的作用；益母草则能够利水消肿、清热解毒。四种中草药结合在一起，能够更好地发挥出泡脚养生的功效。深秋用合适的水温泡脚，不仅可以温暖身体，还能够改善局部血液循环，促进新陈代谢。对于一些寒湿体质的人来说，坚持泡脚还可以改善体质，缓解手脚冰凉、痛经等问题。
              </p>
              <p>
                当然，在泡脚时也需要注意一些细节问题。泡脚最好时间在晚饭后1小时或临睡前；每次泡脚时间一般以30分钟左右为宜；水温最好控制在42°C左右；同时最好选用木桶泡脚，可以增加水的保温效果等。
                深秋时节泡脚养生正当时。通过正确地选择中草药和泡脚方法，可以更好地发挥出泡脚养生的功效。试想一下，当结束了一天的家务、工作或学习，用一盆热水泡泡脚，既舒筋活络，又驱散疲劳，还能安神助眠。
              </p>
            </div>
            <div class="NewsDetails_cont_btm">
              <div></div>
              <p @click="bindBack()">返回列表 ></p>
            </div>
          </div>
        </div>
      </div>

      <div v-show="types == 0" style="text-align: center">
        <img width="90%" v-lazy="mendian[0]" />
        <div class="img" style="width: 90%">
          <img v-lazy="mendian[1]" />
          <img v-lazy="mendian[2]" />
        </div>
        <div class="img" style="width: 90%">
          <img v-lazy="mendian[3]" />
          <img v-lazy="mendian[4]" />
        </div>
      </div>
      <div v-if="types == 1" style="text-align: center">
        <img width="100%" v-lazy="xsscimg" />
      </div>
      <div v-if="types == 2">
        <div class="xueyue" v-if="!lookInfo">
          <div
            class="xueyue-item"
            v-for="(item, index) in list"
            :key="index"
            @click="bindInfo"
          >
            <img v-lazy="item.img" alt="" />
            <div>{{ item.text }}</div>
            <div>{{ item.time }}</div>
          </div>
          <div style="width: 360px; height: 1; opacity: 0"></div>
          <div style="width: 360px; height: 1; opacity: 0"></div>
          <div style="width: 360px; height: 1; opacity: 0"></div>
        </div>
      </div>
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import dheader from "../../components/dheader.vue";
export default {
  components: {
    footer2,
    dheader,
  },
  data() {
    return {
      mendian: [
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xianxia1.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xianxia2.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xianxia3.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xianxia4.png",
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xianxia5.png",
      ],
      types: 0,
      xsscimg:
        "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xianshangshangcheng.png",
      list: [
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xueyuan1.png",
          text: "百姓健康生活",
          time: "2020.11.25",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xueyuan2.png",
          text: "健康生活",
          time: "2020.11.25",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xueyuan3.png",
          text: "中医问诊",
          time: "2020.11.25",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xueyuan4.png",
          text: "百姓健康生活",
          time: "2020.11.25",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xueyuan5.png",
          text: "健康生活",
          time: "2020.11.25",
        },
        {
          img:
            "https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/xueyuan6.png",
          text: "中医问诊",
          time: "2020.11.25",
        },
      ],
      lookInfo: false,
    };
  },
  mounted() {
    this.types = this.$route.params.types;
  },
  watch: {
    "$route.params.types"(old) {
      this.types = old;
    },
  },
  methods: {
    bindTypes(e) {
      this.types = e;
    },
    bindInfo() {
      this.lookInfo = true;
    },
    bindBack() {
      this.lookInfo = false;
    },
  },
};
</script>

<style scoped>
@keyframes loading {
  /* 骨架屏的动画 */
  100% {
    transform: translateX(100%);
  }
}
.product {
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/lvdi.png")
    no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 297px;
}
.product .productDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.xueyue {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.xueyue-item {
  background: #f1f1f1;
  padding-bottom: 20px;
  text-align: center;
  width: 360px;
  margin-bottom: 30px;
}
.xueyue-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.xueyue-item div:nth-child(2) {
  font-size: 20px;
  margin-bottom: 10px;
}
.xueyue-item div:nth-child(3) {
  color: #12a480;
}
.img {
  margin: 0 auto;
}
.img img {
  width: 50%;
}
.main {
  width: 70%;
  margin: 0 auto 30px;
}

/*  */
.NewsDetails_cont {
  width: 100%;
  margin: 0 auto 30px;
}
.NewsDetails_cont_title > h1 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #2b2b2b;
  line-height: 33px;
  margin-top: 78px;
  text-align: center;
}
.NewsDetails_cont_title img {
  vertical-align: middle;
}
.NewsDetails_cont_title_span {
  text-align: center;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a3a3a3;
  line-height: 30px;
  margin-top: 8px;
}
.NewsDetails_cont_text {
  margin-top: 40px;
  border-top: 1px solid #eaeaea;
}
.NewsDetails_cont_text p {
  line-height: 30px;
  color: #727171;
  width: 80%;
  margin: 0 auto;
}

.NewsDetails_cont_img {
  text-align: center;
  margin-bottom: 40px;
}
.NewsDetails_cont_footer {
  padding: 0 40px;

  line-height: 68px;
  width: 1120px;
  height: 68px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm div {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 33px;
}
.NewsDetails_cont_btm p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.NewsDetails_cont_btm2 {
  display: flex;
  justify-content: space-between;
}
.NewsDetails_cont_btm2_1 {
  margin: 32px 0;
  padding: 31px 61px 31px 20px;
  width: 507px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
}
.NewsDetails_cont_btm2_1 div {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #444444;
  line-height: 25px;
}
.NewsDetails_cont_btm2_1 p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}
</style>

<template>
  <div>
    <dheader></dheader>
    <div class="product">
      <div class="productDiv">
        <h1>联系我们</h1>
      </div>
    </div>
    <div class="lx">
      <div class="lx-item">
        <div>
          <div style="margin-bottom:10px">招商电话</div>
          <div>0755-86961543</div>
        </div>
        <div></div>
      </div>
      <div class="lx-item">
        <div>
          <div style="margin-bottom:10px">企业邮箱</div>
          <div>jiugongai@qq.com</div>
        </div>
        <div></div>
      </div>
      <div class="lx-item">
        <div>
          <div style="margin-bottom:10px">企业二维码</div>
          <div>扫描二维码了解</div>
        </div>
        <div>
          <img class="img" src="https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/ewm2.svg" alt="" />
        </div>
      </div>
    </div>
    <footer2></footer2>
  </div>
</template>

<script>
import footer2 from "../../components/footer.vue";
import dheader from "../../components/dheader.vue";
export default {
  components: {
    footer2,
    dheader,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
div {
  font-size: 18px;
}
.product {
  background: url("https://jiugongai-douyin.oss-cn-shanghai.aliyuncs.com/web/image/lvdi.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 297px;
}
.product .productDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lx {
  padding: 132px 0 127px;
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: #727171;
}
.lx-item {
  background: #f1f1f1;
  width: 15%;
  padding: 50px 0;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lx-item div {
  margin-bottom: 10px;
}
.img {
  width: 80px;
  height: 80px;
  margin-left: 10px;
}
</style>